import './map.scss';
import React, { useCallback, useEffect, useState } from "react";
import AppHeader from "../../components/AppHeader/AppHeader";
import { useNavigate, useSearchParams } from "react-router-dom";
import { cityService } from "../../services/city";
import Loading from "../../components/Loading/Loading";
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import { Snackbar } from "@mui/material";
import LoopOutlinedIcon from '@mui/icons-material/LoopOutlined';


type City = {
  _id: string;
  name: string;
  country_id: string;
}

type Cities = City[]

type Landmark = {
  _id: string;
  name: string;
  owned: number;
  spares: number;
  image_url: string;
}

type SelectedCity = City & {
  description: string;
  landmarks: Landmark[];
}


export function Map() {

  const [isSelected, setIsSelected] = useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [cities, setCities] = useState<Cities>([]);
  const [selectedCity, setSelectedCity] = useState<SelectedCity>({
    _id: '',
    name: '',
    country_id: '',
    description: '',
    landmarks: []
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');

  const onButtonClick = (city: string) => {
    const foundCity = cities.find((c) => {
      return c.name.toLowerCase() === city.toLowerCase();
    });
    if(foundCity) {
      cityService.getCity(foundCity._id).then((res: SelectedCity) => {
        if(res) {
          setSelectedCity(res);
          setIsSelected(true);
          setSearchParams({city: res._id});
        }
      })
    }
  }

  const navigateToLandMark = (id: string) => {
    navigate(`/landmark?id=${id}`);
  }

  function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const getCities = useCallback(() => {
    setIsLoading(true);
    sleep(1000).then(() => {
      cityService.getCities().then((r: {cities: Cities, error?: boolean, message: string}) => {
        if(r.cities) {
          setCities(r.cities);
          const city = searchParams.get('city');
          if(city !== null) {
            const foundCity = r.cities.find((c) => {
              return c._id === city;
            });
            if(foundCity) {
              cityService.getCity(foundCity._id).then((res: SelectedCity) => {
                if(res) {
                  setSelectedCity(res);
                  setIsSelected(true);
                  setIsLoading(false);
                } else {
                  setIsLoading(false);
                }
              })
            }
          } else {
            setIsLoading(false);
          }
        }
        if(r.error) {
          setPopupMessage(r.message);
          setShowPopup(true);
          setIsLoading(false);
        }
      })
    })
  }, [searchParams])

  useEffect(() => {
    getCities();
  }, [getCities]);

  const resetMap = () => {
    setIsSelected(false);
    setSelectedCity({
      _id: '',
      name: '',
      country_id: '',
      description: '',
      landmarks: []
    });
    searchParams.delete('city');
    setSearchParams(searchParams);
  }

  const navigateToTradeHub = () => {
    navigate(`/trade_hub`);
  }

  const navigateToBooking = () => {
    navigate(`/booking`);
  }

  return (
    <div className={'map-bg'}>
      <AppHeader title={'Map'}/>
      {isLoading && <Loading  color={''}/>}
      {!isLoading &&
        <div className={'map-inner-bg'} style={{background: "", height: '100vh', padding: '16px'}}>
          <div>
            <div className={'map-breadcrumb'} style={{display: 'flex', justifyContent: 'space-between'}}>
              <div style={{display: 'flex'}}>
                <div style={{paddingTop: '7px', paddingLeft: '6px', display: 'flex', marginRight: '16px'}}>
                  <div>
                    <img style={{marginRight: '8px'}} alt={'map pin'} src={'./map_pin.svg'} width={17} height={21} />
                  </div>
                  <div onClick={() => resetMap()} className={'map-breadcrumb-txt'}>Denmark</div>
                </div>
                {isSelected && <div style={{paddingTop: '7px', display: 'flex'}}>
                  <div>
                    <img style={{marginRight: '8px'}} src={'./city_icon.svg'} alt={'city icon'} width={17} height={21} />
                  </div>
                  <div className={'map-breadcrumb-txt-city'}>{selectedCity.name}</div>
                </div>}
              </div>
              <div>
                {/*Right*/}
                <div style={{paddingTop: '3px'}}>
                  <img style={{marginRight: '8px'}} alt={'breadcrumb icon'} src={'./breadcrumb_icon.svg'} width={29} height={29} />
                </div>
              </div>
            </div>
            <div className={'map-icons-container'}>
              <div className={'map-icon'} style={{marginRight: '12px'}} onClick={() => navigateToTradeHub()}>
                <LoopOutlinedIcon style={{color: '#F9FED5'}} height={'30px'} width={'30px'}  />
              </div>
              <div className={'map-icon'} onClick={() => navigateToBooking()}>
                <LocalActivityIcon style={{color: '#F9FED5'}} height={'24px'} width={'24px'}  />
              </div>
            </div>


            {!isSelected ?
              <div style={{display:'flex', justifyContent: 'center', width: '100%', marginBottom: '12px'}}>

                <div style={{position: 'relative'}}>
                  <img src={'./denmark_map.svg'} alt={'Denmark'}/>
                  <div onClick={() => onButtonClick('aalborg')} style={{top: "95px", left: '95px', position: 'absolute'}}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                      <img src={'./city_icon.svg'} alt={'city icon'} width={25} height={32} />
                    </div>
                    <div className={'map-city-label'}>Aalborg</div>
                  </div>
                  <div onClick={() => onButtonClick('aarhus')} style={{top: "225px", right: '125px', position: 'absolute'}}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                      <img src={'./city_icon.svg'} alt={'city icon'} width={25} height={32} />
                    </div>
                    <div className={'map-city-label'}>Aarhus</div>
                  </div>
                  <div onClick={() => onButtonClick('billund')} style={{top: "265px", left: '55px', position: 'absolute'}}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                      <img src={'./city_icon.svg'} alt={'city icon'} width={25} height={32} />
                    </div>
                    <div className={'map-city-label'}>Billund</div>
                  </div>
                  <div onClick={() => onButtonClick('copenhagen')} style={{top: "280px", right: '-10px', position: 'absolute'}}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                      <img src={'./city_icon.svg'} alt={'city icon'} width={25} height={32} />
                    </div>
                    <div className={'map-city-label'}>Copenhagen</div>
                  </div>
                  <div onClick={() => onButtonClick('esbjerg')} style={{top: "325px", left: '-5px', position: 'absolute'}}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                      <img src={'./city_icon.svg'} alt={'city icon'} width={25} height={32} />
                    </div>
                    <div className={'map-city-label'}>Esbjerg</div>
                  </div>
                  <div onClick={() => onButtonClick('lemvig')} style={{top: "115px", left: '-3px', position: 'absolute'}}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                      <img src={'./city_icon.svg'} alt={'city icon'} width={25} height={32} />
                    </div>
                    <div className={'map-city-label'}>Lemvig</div>
                  </div>
                  <div onClick={() => onButtonClick('nordborg')} style={{top: "390px", left: '70px', position: 'absolute'}}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                      <img src={'./city_icon.svg'} alt={'city icon'} width={25} height={32} />
                    </div>
                    <div className={'map-city-label'}>Nordborg</div>
                  </div>
                  <div onClick={() => onButtonClick('odense')} style={{top: "345px", right: '125px', position: 'absolute'}}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                      <img src={'./city_icon.svg'} alt={'city icon'} width={25} height={32} />
                    </div>
                    <div className={'map-city-label'}>Odense
                    </div>
                  </div>
                  <div onClick={() => onButtonClick('ringkobing')} style={{top: "190px", left: '5px', position: 'absolute'}}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                      <img src={'./city_icon.svg'} alt={'city icon'} width={25} height={32} />
                    </div>
                  </div>
                  <div onClick={() => onButtonClick('ringkobing')} style={{top: "220px", left: '-15px', position: 'absolute'}}>
                    <div className={'map-city-label'}>Ringkobing</div>
                  </div>
                  <div onClick={() => onButtonClick('skagen')} style={{top: "-20px", right: '110px', position: 'absolute'}}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                      <img src={'./city_icon.svg'} alt={'city icon'} width={25} height={32} />
                    </div>
                    <div className={'map-city-label'}>Skagen</div>
                  </div>
                </div>
              </div>
              :
              <div>

                {selectedCity.name.toLowerCase() === 'aalborg' &&
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={{position: 'relative'}}>
                      <img width={350} src={'./aalborg_bg.svg'} alt={'Aalborg Background'} />
                      {selectedCity.landmarks.map((l) =>
                        <div key={l._id}>
                          {l.name.toLowerCase() === 'utzon center' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '120px', right: '130px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={75} height={46} src={l.image_url} alt={'Utzon Center'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === 'budolfi church' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '225px', left: '25px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={30} height={80} src={l.image_url} alt={'Budolfi Church'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === 'house of music' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '170px', right: '0px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={80} height={32} src={l.image_url} alt={'House of Music'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                        </div>
                      )}
                    </div>
                  </div>
                }


                {selectedCity.name.toLowerCase() === 'aarhus' &&
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={{position: 'relative'}}>
                      <img width={350} src={'./aarhus_bg.svg'} alt={'Aarhus Background'} />
                      {selectedCity.landmarks.map((l) =>
                        <div key={l._id}>
                          {l.name.toLowerCase() === 'aros art museum' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '290px', right: '30px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={60} height={60} src={l.image_url} alt={'Aros Art Museum'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === 'the old town museum' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '130px', left: '85px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={70} height={70} src={l.image_url} alt={'The Old Town Museum'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === 'botanical garden' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '0px', left: '70px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={60} height={60} src={l.image_url} alt={'Botanical Garden'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                        </div>
                      )}
                    </div>
                  </div>
                }

                {selectedCity.name.toLowerCase() === 'billund' &&
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={{position: 'relative'}}>
                      <img width={350} src={'./billund_bg.svg'} alt={'Billund Background'} />
                      {selectedCity.landmarks.map((l) =>
                        <div key={l._id}>
                          {l.name.toLowerCase() === 'ted bear museum' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '85px', left: '10px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={80} height={40} src={l.image_url} alt={'Ted Bear Museum'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === 'skulpturpark' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '190px', left: '120px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={56} height={75} src={l.image_url} alt={'Skulpturpark'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === 'aquadome' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '95px', right: '25px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={75} height={56} src={l.image_url} alt={'Aquadome'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                        </div>
                      )}
                    </div>
                  </div>
                }

                {selectedCity.name.toLowerCase() === 'copenhagen' &&
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={{position: 'relative'}}>
                      <img width={350} src={'./copenhagen_bg.svg'} alt={'Copenhagen Background'} />
                      {selectedCity.landmarks.map((l) =>
                        <div key={l._id}>
                          {l.name.toLowerCase() === 'little mermaid statue' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '40px', right: '20px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={60} height={60} src={l.image_url} alt={'Little Mermaid Statue'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === 'opera house' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '195px', right: '10px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={70} src={l.image_url} alt={'Opera House'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === 'tivoli gardens' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '285px', left: '30px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={70} height={50} src={l.image_url} alt={'Tivoli Gardens'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                        </div>
                      )}
                    </div>
                  </div>
                }

                {selectedCity.name.toLowerCase() === 'esbjerg' &&
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={{position: 'relative'}}>
                      <img width={400} src={'./esbjerg_bg.svg'} alt={'Esbjerg Background'} />
                      {selectedCity.landmarks.map((l) =>
                        <div key={l._id}>
                          {l.name.toLowerCase() === 'esbjerg museum' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '60px', right: '90px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={90} height={60} src={l.image_url} alt={'Esbjerg Museum'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === 'port of esbjerg' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '310px', left: '15px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={90} height={60} src={l.image_url} alt={'Port of Esbjerg'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === 'performing arts centre' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '320px', left: '160px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={90} height={40} src={l.image_url} alt={'Performing Arts Centre'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                        </div>
                      )}
                    </div>
                  </div>
                }

                {selectedCity.name.toLowerCase() === 'lemvig' &&
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={{position: 'relative'}}>
                      <img width={350} src={'./lemvig_bg.svg'} alt={'Lemvig Background'} />
                      {selectedCity.landmarks.map((l) =>
                        <div key={l._id}>
                          {l.name.toLowerCase() === 'aa-mølle`laug watermill' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '150px', right: '-5px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={75} height={65} src={l.image_url} alt={'Aa-Mølle`laug Watermill'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === 'pastor svolm man-sized figure' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute',  top: '90px', right: '120px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={70} src={l.image_url} alt={'Pastor Svolm Man-sized Figure'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === 'bovbjerg lighthouse' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute',top: '190px', left: '-10px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={75} height={60} src={l.image_url} alt={'Bovbjerg Lighthouse'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                        </div>
                      )}
                    </div>
                  </div>
                }

                {selectedCity.name.toLowerCase() === 'nordborg' &&
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={{position: 'relative'}}>
                      <img width={350} src={'./nordborg_bg.svg'} alt={'Nordborg Background'} />
                      {selectedCity.landmarks.map((l) =>
                        <div key={l._id}>
                          {l.name.toLowerCase() === 'jollmands gaard' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute',top: '165px', left: '0px' }}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={80} src={l.image_url} alt={'Jollmands Gaard'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === 'nordborg kirke' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '75px', right: '120px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={80} src={l.image_url} alt={'Nordborg Kirke'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === 'havnbjerg mill' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '200px', right: '5px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={60} src={l.image_url} alt={'Havnbjerg Mill'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                        </div>
                      )}
                    </div>
                  </div>
                }

                {selectedCity.name.toLowerCase() === 'odense' &&
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={{position: 'relative'}}>
                      <img width={350} src={'./odense_bg.svg'} alt={'Odense Background'} />
                      {selectedCity.landmarks.map((l) =>
                        <div key={l._id}>
                          {l.name.toLowerCase() === 'the funen village' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '275px', left: '15px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={70} src={l.image_url} alt={'The Funen Village'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === 'railway museum' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '110px', left: '15px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={70} src={l.image_url} alt={'Railway Museum'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === 'sanderumgaard romantic garden' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '270px', right: '20px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={60} src={l.image_url} alt={'Sanderumgaard Romantic Garden'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                        </div>
                      )}
                    </div>
                  </div>
                }

                {selectedCity.name.toLowerCase() === 'ringkobing' &&
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={{position: 'relative'}}>
                      <img width={350} src={'./ringkobing_bg.svg'} alt={'Ringkobing Background'} />
                      {selectedCity.landmarks.map((l) =>
                        <div key={l._id}>
                          {l.name.toLowerCase() === 'ringkøbing-skjern museum' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '265px', right: '25px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={70} src={l.image_url} alt={'Ringkøbing-Skjern Museum'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === 'sand sculpture festival' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '190px', left: '-5px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={80} height={50} src={l.image_url} alt={'Sand Sculpture Festival'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === "jette's strudsefarm" &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '50px', left: '10px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={70} src={l.image_url} alt={"Jette's Strudsefarm"}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                        </div>
                      )}
                    </div>
                  </div>
                }

                {selectedCity.name.toLowerCase() === 'skagen' &&
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={{position: 'relative'}}>
                      <img width={350} src={'./skagen_bg.svg'} alt={'Skagen Background'} />
                      {selectedCity.landmarks.map((l) =>
                        <div key={l._id}>
                          {l.name.toLowerCase() === 'skagen lighthouse' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '80px', right: '-15px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={70} src={l.image_url} alt={'Skagen Lighthouse'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === 'vippefyret' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '180px', left: '150px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={52} height={70} src={l.image_url} alt={'Vippefyret'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === 'kystmuseet' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '280px', left: '35px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={70} src={l.image_url} alt={'Kystmuseet'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                        </div>
                      )}
                    </div>
                  </div>
                }


              </div>
            }


          </div>
        </div>
      }
      <Snackbar
        open={showPopup}
        autoHideDuration={3000}
        onClose={() => setShowPopup(false)}
        message={popupMessage}
        ContentProps={{
          sx: {
            display: 'block',
            textAlign: "center"
          }
        }}
      />
    </div>
  )
}