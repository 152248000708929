import './landmark.scss';
import React, { useCallback, useEffect, useState } from "react";
import AppHeader from "../../components/AppHeader/AppHeader";
import { Button, Dialog, DialogContent, DialogProps } from "@mui/material";
import { landmarkService } from "../../services/landmark";
import { useNavigate, useSearchParams } from "react-router-dom";
import Loading from "../../components/Loading/Loading";
import MatchTwoGame from "../../components/MatchTwoGame/MatchTwoGame";
import HangmanGame from "../../components/HangmanGame/HangmanGame";
import Trivia from "../../components/Trivia/Trivia";
import MatchAndGoGame from "../../components/MatchAndGoGame/MatchAndGoGame";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import WordleGame from "../../components/Wordle/Wordle";
import SlidePuzzle from "../../components/SlidePuzzle/SlidePuzzle";
import MatchThree from "../../components/MatchThree/MatchThree";
import Minesweeper from "../../components/Minesweeper/Minesweeper";

type LandmarkDetails = {
  description: string;
  green_tickets: number;
  blue_tickets: number;
  gold_tickets: number;
  failed_attempts: number;
  game_enabled: boolean;
  in_progress: boolean;
  landmark_id: string;
  off_hours: boolean;
  open_in: number;
  souvenir_won: number;
  ticket_required: string | null;
  name: string;
  game_type: string;
  image_url: string;
  city_name: string;
  city_id: string;
}

export function Landmark() {

  const [searchParams,] = useSearchParams();
  const [landmarkDetails, setLandmarkDetails] = useState<LandmarkDetails>({
    description: '',
    green_tickets: 0,
    blue_tickets: 0,
    gold_tickets: 0,
    failed_attempts: 0,
    game_enabled: false,
    in_progress: false,
    landmark_id: '',
    off_hours: false,
    open_in: 0,
    souvenir_won: 0,
    ticket_required: null,
    name: '',
    game_type: '',
    image_url: '',
    city_name: '',
    city_id: ''
  });

  const [isLoading, setIsLoading] = useState(false);
  const [gameInProgress, setGameInProgress] = useState(false);
  const navigate = useNavigate();
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const getLandmark = useCallback(() => {
    setIsLoading(true);
    sleep(1000).then(() => {
      landmarkService.getLandmark(searchParams.get('id') as string).then((r: LandmarkDetails) => {
        setLandmarkDetails(r);
        if(r && r.in_progress) {
          setGameInProgress(true);
        }
        setIsLoading(false);
      })
    })
  }, [searchParams]);

  const useTicket = () => {
    if(landmarkDetails.ticket_required === 'gold') {
      setShowConfirmationDialog(true);
    } else {
      setGameInProgress(true);
    }
  }

  const onConfirmation = () => {
    setShowConfirmationDialog(false);
    setGameInProgress(true);
  }

  const closeConfirmationDialog: DialogProps["onClose"] = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    setShowConfirmationDialog(false);
  }

  useEffect(() => {
    getLandmark();
  }, [getLandmark]);

  const navigateToMap = () => {
    navigate(`/map`);
  }

  const navigateToCity = () => {
    navigate(`/map?city=${landmarkDetails.city_id}`);
  }

  return (
    <div style={{minHeight: '100vh', paddingTop: '48px'}}>
      {isLoading && <Loading color={'#E81961'} />}
      {!isLoading &&
        <div>
          <AppHeader title={landmarkDetails.name}/>

          {gameInProgress &&
            <div>
              {landmarkDetails.game_type === 'match' &&
                <div>
                  <MatchTwoGame landmark_name={landmarkDetails.name} landmark_id={landmarkDetails.landmark_id} />
                </div>
              }
              {landmarkDetails.game_type === 'hangman' &&
                <div>
                  <HangmanGame landmark_name={landmarkDetails.name} landmark_id={landmarkDetails.landmark_id} />
                </div>
              }
              {landmarkDetails.game_type === 'trivia' &&
                <div>
                  <Trivia landmark_name={landmarkDetails.name} landmark_id={landmarkDetails.landmark_id} />
                </div>
              }
              {landmarkDetails.game_type === 'match_and_go' &&
                <div>
                  <MatchAndGoGame landmark_name={landmarkDetails.name} landmark_id={landmarkDetails.landmark_id} />
                </div>
              }
              {(landmarkDetails.game_type === 'wordathon') &&
                <div>
                  <WordleGame landmark_name={landmarkDetails.name} landmark_id={landmarkDetails.landmark_id} />
                </div>
              }
              {(landmarkDetails.game_type === 'slide_puzzle') &&
                <div>
                  <SlidePuzzle landmark_name={landmarkDetails.name} landmark_id={landmarkDetails.landmark_id} />
                </div>
              }
              {(landmarkDetails.game_type === 'match_3') &&
                <div>
                  <MatchThree landmark_name={landmarkDetails.name} landmark_id={landmarkDetails.landmark_id} />
                </div>
              }
              {(landmarkDetails.game_type === 'minesweeper') &&
                <div>
                  <Minesweeper landmark_name={landmarkDetails.name} landmark_id={landmarkDetails.landmark_id} />
                </div>
              }
            </div>
          }

          {!gameInProgress &&
            <div className={'landmark-bg'}>
              <div className={'landmark-inner-bg'} style={{background: "", height: '100vh', padding: '16px'}}>

                <div className={'landmark-breadcrumb'} style={{display: 'flex', justifyContent: 'space-between'}}>
                  <div style={{display: 'flex'}}>
                    <div style={{paddingTop: '7px', paddingLeft: '6px', display: 'flex', marginRight: '16px'}}>
                      <div>
                        <img style={{marginRight: '8px'}} alt={'italy pin'} src={'./map_pin.svg'} width={17} height={21} />
                      </div>
                      <div onClick={() => navigateToMap()} className={'landmark-breadcrumb-txt'}>Denmark</div>
                    </div>
                    <div style={{paddingTop: '7px', display: 'flex'}}>
                      <div>
                        <img style={{marginRight: '8px'}} src={'./city_icon.svg'} alt={'city icon'} width={17} height={21} />
                      </div>
                      <div onClick={() => navigateToCity()} className={'landmark-breadcrumb-txt-city'}>{landmarkDetails.city_name}</div>
                    </div>
                  </div>
                  <div>
                    <div style={{paddingTop: '3px'}}>
                      <img style={{marginRight: '8px'}} alt={'breadcrumb icon'} src={'./breadcrumb_icon.svg'} width={29} height={29} />
                    </div>
                  </div>
                </div>

                <div style={{display: 'flex', justifyContent: 'center', position: 'relative', marginBottom: '12px'}}>
                  <div>
                    {landmarkDetails.city_name.toLowerCase() === 'aalborg'
                      &&  <img alt={'Aalborg'} className={'landmark-bg-img'} width={280} height={280} src={'./aalborg_bg.svg'}
                      />}

                    {landmarkDetails.city_name.toLowerCase() === 'aarhus'
                      &&  <img alt={'Aarhus'} className={'landmark-bg-img'} width={280} height={280} src={'./aarhus_bg.svg'}
                      />}

                    {landmarkDetails.city_name.toLowerCase() === 'billund'
                      &&  <img alt={'Billund'} className={'landmark-bg-img'} width={280} height={280} src={'./billund_bg.svg'}
                      />}

                    {landmarkDetails.city_name.toLowerCase() === 'copenhagen'
                      &&  <img alt={'Copenhagen'} className={'landmark-bg-img'} width={280} height={280} src={'./copenhagen_bg.svg'}
                      />}

                    {landmarkDetails.city_name.toLowerCase() === 'esbjerg'
                      &&  <img alt={'Esbjerg'} className={'landmark-bg-img'} width={280} height={280} src={'./esbjerg_bg.svg'}
                      />}

                    {landmarkDetails.city_name.toLowerCase() === 'lemvig'
                      &&  <img alt={'Lemvig'} className={'landmark-bg-img'} width={280} height={280} src={'./lemvig_bg.svg'}
                      />}

                    {landmarkDetails.city_name.toLowerCase() === 'nordborg'
                      &&  <img alt={'Nordborg'} className={'landmark-bg-img'} width={280} height={280} src={'./nordborg_bg.svg'}
                      />}

                    {landmarkDetails.city_name.toLowerCase() === 'odense'
                      &&  <img alt={'Odense'} className={'landmark-bg-img'} width={280} height={280} src={'./odense_bg.svg'}
                      />}

                    {landmarkDetails.city_name.toLowerCase() === 'ringkobing'
                      &&  <img alt={'Ringkobing'} className={'landmark-bg-img'} width={280} height={280} src={'./ringkobing_bg.svg'}
                      />}

                    {landmarkDetails.city_name.toLowerCase() === 'skagen'
                      &&  <img alt={'Skagen'} className={'landmark-bg-img'} width={280} height={280} src={'./skagen_bg.svg'}
                      />}

                    <img className={'landmark-pic'} alt={landmarkDetails.name} src={landmarkDetails.image_url} />
                  </div>
                </div>

                <div className={'landmark-info-box'}>
                  <div  className={'landmark-info-name'}>
                    {landmarkDetails.name}
                  </div>
                  <div  className={'landmark-info-description'}>
                    {landmarkDetails.description}
                  </div>
                </div>

                <div className={'landmark-souv-info'}>
                  <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div style={{display: 'flex'}}>
                      <img style={{marginRight:'8px'}} src={'./landmark_won.svg'} height={32} width={32} alt={'Landmark won'}/>
                      <div>
                        <div className={'landmark-souv-txt'}>Souvenirs won</div>
                        <div className={'landmark-souv-txt'}>{landmarkDetails.souvenir_won}</div>
                      </div>
                    </div>
                    <div style={{display: 'flex'}}>
                      <img style={{marginRight:'8px'}} src={'./landmark_failed.svg'} height={32} width={32} alt={'Landmark won'}/>
                      <div>
                        <div className={'landmark-souv-txt'}>Failed Attempts</div>
                        <div className={'landmark-souv-txt'}>{landmarkDetails.failed_attempts}</div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div className={'landmark-souv-txt'}>
                      Tickets
                    </div>
                    <div style={{display: 'flex'}}>
                      <div style={{display: 'flex', marginRight: '12px'}}>
                        <div style={{marginRight: '4px', paddingTop: '2px'}}  className={'landmark-souv-txt'}>
                          {landmarkDetails.green_tickets}
                        </div>
                        <div>
                          <img alt={'green ticket'} src={'./green_ticket_small.svg'}/>
                        </div>
                      </div>
                      <div style={{display: 'flex', marginRight: '12px'}}>
                        <div  style={{marginRight: '4px', paddingTop: '2px'}} className={'landmark-souv-txt'}>
                          {landmarkDetails.blue_tickets}
                        </div>
                        <div>
                          <img alt={'blue ticket'} src={'./blue_ticket_small.svg'}/>
                        </div>
                      </div>
                      <div style={{display: 'flex', marginRight: '12px'}}>
                        <div  style={{marginRight: '4px', paddingTop: '2px'}} className={'landmark-souv-txt'}>
                          {landmarkDetails.gold_tickets}
                        </div>
                        <div>
                          <img alt={'gold ticket'} src={'./gold_ticket_small.svg'}/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{textAlign: 'center'}}>
                    {landmarkDetails.off_hours  ?
                      <div className={'landmark-souv-txt-closed'}>Sorry we're closed today!</div>
                      : <div className={'landmark-souv-txt'}>We're open today!</div> }

                    {((landmarkDetails.ticket_required === 'blue' || landmarkDetails.ticket_required === 'gold') && landmarkDetails.off_hours) ?
                      <div className='landmark-souv-txt'>
                        Use <span style={{textTransform: 'capitalize'}}>{landmarkDetails.ticket_required}</span> ticket or wait {landmarkDetails.open_in} days to enter with Green ticket.
                      </div>
                      :
                      <div className={'landmark-souv-txt'}>
                        {landmarkDetails.ticket_required !== null ?
                          <span>
                            Use <span style={{textTransform: 'capitalize'}}>{landmarkDetails.ticket_required}</span> ticket to play now
                          </span>
                          : ''}
                      </div>
                    }


                  </div>
                </div>

                <div style={{display: 'flex', justifyContent: 'center', position: 'relative'}}>
                  <Button
                    onClick={useTicket}
                    disabled={landmarkDetails.ticket_required === null}
                    className={(landmarkDetails.ticket_required !== null) ? 'landmark-play-btn' : 'landmark-play-btn-dsbl'}
                    >
                    <img style={{position: 'absolute', left: '24px'}} src={'./play_btn.svg'} alt={'Play button icon'} width={27} height={27} />
                    Play Mini-game
                  </Button>
                </div>
              </div>
            </div>
          }
        </div>
      }

      <Dialog
        open={showConfirmationDialog}
        onClose={closeConfirmationDialog}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              borderRadius: "14px",
              background: "#FFF",
              margin: 0
            },
          },
        }}
      >
        <DialogContent>
          <div>
            <div  onClick={() => closeConfirmationDialog({}, 'escapeKeyDown')} style={{color: "#CCCCCC", position: 'absolute', right: '4px', top: '4px', display: 'inline-block'}}>
              <HighlightOffIcon width={'28px'} height={'28px'} />
            </div>
            <div className={'landmark-confirmation-dialog-txt'} style={{marginBottom: '24px', marginTop: '16px'}}>
              Are you sure you want to use a Gold Ticket?
            </div>
            <div className={'landmark-confirmation-dialog-txt'} style={{marginBottom: '24px'}}>
              Gold Tickets are rare and can be used anywhere at any time but each ticket can only be used once.
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <Button onClick={() => closeConfirmationDialog({}, 'escapeKeyDown')} className='confirmation-dialog-btn'>DON'T USE</Button>
              <Button onClick={() => onConfirmation()} className='confirmation-dialog-btn'>USE</Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>


    </div>
  )
}