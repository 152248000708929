import { Navigate, Outlet, useLocation } from "react-router-dom";
import {
  Box,
  CssBaseline} from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import { Global } from "@emotion/react";
import "./protected.css";


const Root = styled('div')(({ theme }) => ({
  height: '100%'
}));

function Protected() {
  const getCookie = (cname: string) => {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }


  const location = useLocation();


  const lsToken = localStorage.getItem('rr_token');
  const cookieToken = getCookie("oe_t");
  const cookieDomain = getCookie("domain");
  const cookiePath = getCookie("path");

  if(cookieToken !== '') {
    localStorage.setItem('rr_token', cookieToken);
    document.cookie = `oe_t=; expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=${cookieDomain}; path=${cookiePath};`;
  }


  if (!lsToken && cookieToken === '') {
    return <Navigate to="/error" replace state={{ from: location }}/>;
  }
  return (
    <Root>
      <CssBaseline />
      <Global
        styles={{
          '.MuiDrawer-root > .MuiPaper-root': {
            overflow: 'visible',
            opacity: 0.9
          },
        }}
      />
      <Box sx={{ }}>
        <Outlet/>
      </Box>
    </Root>
  )
}
export default Protected
